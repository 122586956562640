.large{
  height:900px
}

.thumbnail{
  position:relative;
  --tw-bg-opacity:1;
  background-color:rgba(229, 231, 235, var(--tw-bg-opacity))
}

.cyan{
  --tw-bg-opacity:1;
  background-color:rgba(175, 240, 240, var(--tw-bg-opacity))
}

.green{
  --tw-bg-opacity:1;
  background-color:rgba(201, 245, 217, var(--tw-bg-opacity))
}

.content{
  padding:1rem
}

@media (min-width: 768px){
  .content{
    padding:2rem
  }
}

@media (min-width: 1024px){
  .content{
    padding:2.5rem
  }
}

.content.lg .title{
  font-size:1.25rem;
  line-height:1.75rem
}

@media (min-width: 768px){
  .content.lg .title{
    font-size:1.25rem;
    line-height:1.75rem
  }
}

@media (min-width: 1024px){
  .content.lg .title{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.content.md .title{
  font-size:1.25rem;
  line-height:1.75rem
}

@media (min-width: 768px){
  .content.md .title{
    font-size:1.125rem;
    line-height:1.75rem
  }
}

@media (min-width: 1024px){
  .content.md .title{
    font-size:1.25rem;
    line-height:1.75rem
  }
}

.content .title{
  font-size:0.875rem;
  line-height:1.25rem;
  font-weight:700;
  overflow:hidden;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:1
}

@media (min-width: 1024px){
  .content .description{
    margin-top:1rem;
    margin-bottom:1rem
  }
}

.content .description{
  margin-top:0.25rem;
  margin-bottom:0.25rem
}

@media (min-width: 768px){
  .content .description{
    margin-top:0.5rem;
    margin-bottom:0.5rem
  }
}

.content .description{
  font-size:1rem;
  line-height:1.5rem;
  overflow:hidden;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2
}

.content .date{
  margin-top:0.5rem;
  margin-bottom:0.5rem;
  font-size:0.875rem;
  line-height:1.25rem
}

@media (min-width: 1024px){
  .content .date{
    font-size:1rem;
    line-height:1.5rem
  }
}