
.large {
	height: 900px;
}

.thumbnail {
	@apply bg-gray-200 relative;
}

.cyan {
	@apply bg-cyan
}

.green {
	@apply bg-secondary-light;
}

.content {
	@apply md:p-8 lg:p-10 p-4;

	&.lg {
		.title {
			@apply lg:text-3xl md:text-xl text-xl;
		}
	}

	&.md {
		.title {
			@apply lg:text-xl md:text-lg text-xl;
		}
	}

	.title {
		@apply text-sm font-bold line-clamp-1;
	}

	.description {
		@apply lg:my-4;
		@apply md:my-2 my-1;
		@apply text-base line-clamp-2;
	}

	.date {
		@apply my-2 text-sm lg:text-base;
	}

}